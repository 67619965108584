import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SectionCards = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .text-stroke {
    color: white;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black;
    width: 200px;
    padding: 5px;
    font-size: 20px;
    overflow: hidden; /* oculta qualquer texto que vá além da largura especificada */
    text-overflow: ellipsis;
  }
`;

export const ContainerCard = styled.div`
  position: relative;

  .btn-edit {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0.6);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.5s linear;

    &:hover {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
  .btn-edit2 {
    position: absolute;
    top: 20px;
    left: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0.6);
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.5s linear;
    font-size: 11px;
  }
`;

export const CardButton = styled(motion.button)`
  width: 200px;
  height: 200px;
  border-radius: 0.25rem;
  margin: 0px 10px 10px 0px;
  border: none;
  transition: all 0.25s ease-in-out;
  font-family: 'Poppins', 'Roboto', sans-serif;

  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  opacity: 0.8;
  transform: scale(0.8);
  &.novo {
    background-color: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
  }
  &:focus {
    outline: none;
    opacity: 1;
  }
  &:hover {
    opacity: 1;

    transform: scale(1.05);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  ul {
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 63px;
    padding: 0px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 500;

      svg {
        margin-right: 5px;
        font-size: 24px;
      }

      b {
        margin-right: 5px;
      }
    }
  }
`;
