import React, { useContext } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { v4 } from 'uuid';
import { useHistoricoProduto } from '../HistoricoProduto/HistoricoProdutoContext';
import { ThemeContext } from 'styled-components';
import { ClipLoader } from 'react-spinners';

interface FormProps {
  rows: any[];
  columns: any[];
  hasTitle?: boolean;
  minHeight?: string;
  maxHeight?: string;
  errorMessage?: string;
  loading?: boolean;
}

/**
 * Linhas e colunas para exemplo
 */

const ExColumns: any[] = [
  {
    id: 'nome',
    fixed: true,
    left: 0,
    label: 'Nome',
    width: '300px',
    minWidth: '100px',
    align: 'right',
  },
  {
    id: 'apelido',
    fixed: true,
    left: 100,
    label: 'Apelido',
    width: '100px',
    minWidth: '100px',
    align: 'right',
  },
  {
    id: 'idade',
    label: 'Idade',
    align: 'right',
  },
  {
    id: 'tipo',
    label: 'Tipo',
    align: 'right',
  },
  {
    id: 'inativo',
    label: 'Inativo',
    align: 'right',
  },
];

const ExRows = [
  {
    nome: 'Teste',
    apelido: 'teste',
    idade: '23',
    tipo: 'Admin',
    inativo: 'Não',
  },
  {
    nome: 'Teste1',
    apelido: 'teste1',
    idade: '23',
    tipo: 'Admin',
    inativo: 'Não',
  },
  {
    nome: 'Teste2',
    apelido: 'teste2',
    idade: '23',
    tipo: 'Admin',
    inativo: 'Não',
  },
];

/**
 * @param rows Array de objetos contendo as linhas da tabela (as keys dos objetos devem ser iguais as ids das colunas)
 * @param columns Array de objetos contendo as colunas
 * @param columns Para fixar uma coluna basta passar uma chave chamada fixed com o valor true e Left com 0 (também é necessário passar um width e um minWidth)
 * @param columns Caso mais de uma coluna seja fixa, a coluna seguinte deverá receber o valor de Left igual ao minWidth da coluna anterior
 * @returns Componente DataGrid personalizado
 */

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    hasTitle = true,
    loading = false,
    minHeight = 'none',
    maxHeight = '400px',
    errorMessage = 'Nenhum registro encontrado',
  } = props;

  const { colors } = useContext(ThemeContext);

  const { limitExtrato, handlePagetExt, handleLimitExt, titleExtrato } =
    useHistoricoProduto();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    handlePagetExt(newPage === 0 ? 1 : newPage + 1);
    handleLimitExt(rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    handlePagetExt(1);
    handleLimitExt(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper style={{ width: '100%', zIndex: 1 }}>
        <TableContainer style={{ maxHeight, minHeight }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {hasTitle && (
                <TableRow>
                  <TableCell align="center" colSpan={3} />
                  <TableCell align="center" colSpan={2}>
                    Tipos
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* adicionar hide */}
                {columns.map((column) => {
                  if (column.fixed)
                    return (
                      <TableCell
                        key={column.id}
                        style={{
                          position: 'sticky',
                          left: column.left,
                          width: column.width,
                          minWidth: column.minWidth,
                          background: '#fafafa',
                          zIndex: 998,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  return (
                    <TableCell
                      align={column.alignColumn}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {rows.length > 0 && !loading && (
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={v4()}>
                      {columns.map((column) => {
                        if (column.fixed)
                          return (
                            <TableCell
                              key={column.id}
                              style={{
                                position: 'sticky',
                                left: column.left,
                                width: column.width,
                                minWidth: column.minWidth,
                                background: '#fafafa',
                                zIndex: 998,
                              }}
                            >
                              {row[column.id]}
                            </TableCell>
                          );
                        return (
                          <TableCell align={column.align} key={column.id}>
                            {row[column.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <ClipLoader color={colors.primary} />
            </div>
          )}

          {rows.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <p>{errorMessage}</p>{' '}
            </div>
          )}
        </TableContainer>
        {titleExtrato === 'estoque' && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 30]}
            count={limitExtrato}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
};

export default CustomDataGrid;
