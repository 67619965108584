import React, { useState, useEffect, useCallback } from 'react';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';

import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import Loja from '~/pages/Loja';
import InputSelect from '../Inputs/InputSelect';
import { Container } from './styles';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface ISelect {
  label: string;
  value: number;
}

interface ICategoria {
  cod_subcategoria: number;
  descricao: string;

  tipo_categoria: number;
}

interface CategoriaProps {
  value: Options | undefined | null;
  // nivel: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
  tipoCategoria?: string;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const Categoria: React.FC<CategoriaProps> = (props) => {
  const {
    onChange,
    value,
    isRequired,
    isDisabled,
    setInvalid,
    iniInicializado,
    tipoCategoria = 'Ambos',
    menuPosition,
    menuPlacement,
    minMenuHeight,
  } = props;
  // const user = useAuth();
  // const { options } = props;
  // armazena se o input passou na validação

  // armazena se a validação foi iniciada
  const [optionsCategoria, setOptionsCategoria] = useState<Options[]>([]);
  const [categorias, setCategorias] = useState<ISelect>();

  useEffect(() => {
    (async () => {
      const tipo_categoria = tipoCategoria === 'Despesa' ? 1 : 0;
      const { data } = await api.get(`/categorias/${tipo_categoria}`);
      if (data.success && data.data) {
        const options = data.data.map((categoria: ICategoria) => ({
          label: `${categoria.descricao}`,
          value: categoria.cod_subcategoria,
        }));
        setOptionsCategoria(options);
      }
    })();
  }, [tipoCategoria]);

  useEffect(() => {
    (async () => {
      if (tipoCategoria === 'Ambos') {
        const { data } = await api.get(`/categorias`);
        if (data.success && data.data) {
          const options: Array<{ label: string; value: number }> =
            data.data.map((categoria: ICategoria) => ({
              label: `${categoria.descricao}`,
              value: categoria.cod_subcategoria,
            }));

          const dispesas = [];
          const receita = [];
          const outros = [];

          for (let index = 0; options.length > index; index++) {
            const splits = options[index].label.split(' ', 2);

            if (splits[0] === '(+)') {
              receita.push(options[index]);
            } else if (splits[0] === '(-)') {
              dispesas.push(options[index]);
            } else {
              outros.push(options[index]);
            }
          }
          setOptionsCategoria([...receita, ...dispesas, ...outros]);
        }
      }
    })();
  }, []);

  const handleChangeCategoria = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
      setCategorias(val);
    },
    [onChange],
  );

  return (
    <Container>
      <div className="col-sm-12">
        <InputSelect
          options={[
            {
              label: 'Nenhuma',
              value: undefined,
            },
            ...optionsCategoria,
          ]}
          label="Categoria"
          value={
            optionsCategoria.find((categoria: Options) => {
              return categoria.value === value?.value;
            }) ?? null
          }
          placeholder="Selecione"
          noOptionsMessage="Nenhum registro encontrado"
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          iniInicializado={iniInicializado}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangeCategoria(newValue, isInvalid);
          }}
        />
      </div>
    </Container>
  );
};

export default Categoria;
