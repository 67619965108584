import Button from '@atlaskit/button';
import { Field } from '@atlaskit/form';

import Textfield from '@atlaskit/textfield';

import api from '~/services/api';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';

import { FaRegWindowRestore } from 'react-icons/fa';

import { Span } from '../Span';
import { AlertBox } from './styles';
import { ModalContabil } from './Components/Modal';

interface FormProps {
  value: string | number | undefined;
  isRequired?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  onChange(data: any, data1: boolean, codConta: number): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  label?: string;
  lengthBuscaConta?: number;
  focus?: boolean;
}
type Conta = {
  cod_conta: number;
  conta_contabil: string;
  conta_reduzida: string;
  des_conta_contabil: string;
};

/**
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 * @value Valor correspondente ao cod_conta
 */
const ContaContabil: React.FC<FormProps> = (props) => {
  const {
    value,
    isRequired = false,
    onChange,
    min = 100,
    max = 9999999999999,
    lengthBuscaConta = 3,
    maxLength = 13,
    isUndefined = false,
    isNull = null,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    label = 'Conta Contábil',
    focus = true,
  } = props;
  const timeoutRef = useRef<number | null>(null);

  const [inicializado, setInicializado] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mensagemBusca, setMensagemBusca] = useState<string>('');
  const [valueInput, setValueInput] = useState<any>(null);

  const [conta, setConta] = useState<Conta>({
    cod_conta: 0,
    conta_contabil: '',
    conta_reduzida: '',
    des_conta_contabil: '',
  });

  useEffect(() => {
    if (value === '') {
      setConta({
        cod_conta: 0,
        conta_contabil: '',
        conta_reduzida: '',
        des_conta_contabil: '',
      });
      setMensagemBusca('');
      setValueInput('');
    }
    if (value !== '' && (valueInput === '' || valueInput === null)) {
      setValueInput(value);

      handleContas(String(value));
    }
  }, [value]);

  const validation = useCallback(() => {
    if (inicializado && isRequired) {
      if (isUndefined && valueInput === undefined) {
        setIsInvalid(true);
        return;
      }
      if (isNull && valueInput === null) {
        setIsInvalid(true);
        return;
      }
    }
    if (inicializado) {
      if (Number(valueInput) < min) {
        setIsInvalid(true);
        return;
      }
      if (Number(valueInput) > max) {
        setIsInvalid(true);
        return;
      }
    }

    setIsInvalid(false);
    setInicializado(true);
  }, [valueInput, inicializado, isNull, isRequired, isUndefined, max, min]);

  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const handleContas = useCallback(
    async (value_cod_conta: string) => {
      if (value_cod_conta) {
        setMensagemBusca('');
        const valueParseString = String(value_cod_conta);

        if (valueParseString.length > lengthBuscaConta) {
          try {
            const { data } = await api.get(
              `/contaContabil/${valueParseString}`,
            );
            if (data.success) {
              setConta({
                cod_conta: data.data[0].cod_conta,
                conta_contabil: data.data[0].conta_contabil,
                conta_reduzida: data.data[0].conta_reduzida,
                des_conta_contabil: data.data[0].des_conta_contabil,
              });

              onChange(value_cod_conta, false, data.data[0].cod_conta);
            }
          } catch (error: any) {
            setConta({
              cod_conta: 0,
              conta_contabil: '',
              conta_reduzida: '',
              des_conta_contabil: '',
            });
            setMensagemBusca('Conta contábil não encontrada');
            onChange(value_cod_conta, true, 0);
          }
        }
      } else {
        onChange(value_cod_conta, true, 0);
      }
    },
    [lengthBuscaConta, onChange, value],
  );

  // Essa função evita que seja feita uma requisição
  // a cada caractere digitado. Em vez disso, espera 500ms após o último
  // caractere antes de realizar a requisição.
  const debouncedHandleContas = (cod_conta_input: any) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      handleContas(cod_conta_input);
    }, 500);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'e' || event.key.toLowerCase() === '-') {
      event.preventDefault();
    }
  };

  return (
    <>
      <ModalContabil
        showModal={showModal}
        value={value}
        setConta={setConta}
        onChange={(val: any, isInvalid2: any, cod_conta: number) => {
          setValueInput(val);
          setIsInvalid(false);
          onChange(val, true, cod_conta);
        }}
        setShowModal={setShowModal}
      />
      <AlertBox>
        <div>
          <Field label={label} name="contaContabil">
            {({ fieldProps }) => (
              <div className="grupo">
                <Textfield
                  {...fieldProps}
                  className={`${
                    inicializado && isInvalid ? 'alert-class' : ''
                  } `}
                  type="number"
                  min={min}
                  max={max}
                  maxLength={maxLength}
                  step="1"
                  autoFocus={focus}
                  value={valueInput}
                  placeholder="Número conta reduzida "
                  isDisabled={isDisabled}
                  onKeyDown={handleKeyDown}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length <= maxLength) {
                      setConta({
                        cod_conta: 0,
                        conta_contabil: '',
                        conta_reduzida: '',
                        des_conta_contabil: '',
                      });

                      debouncedHandleContas(e.target.value);
                      validation();
                      setValueInput(e.target.value);
                    }
                  }}
                  style={{ textAlign: 'right' }}
                />
                <Button
                  style={{ background: '#2773ca' }}
                  type="button"
                  className="inputButtons"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <FaRegWindowRestore
                    style={{ color: '#fff', marginTop: '5px' }}
                    size={15}
                  />
                </Button>
                <Span
                  className="span"
                  value={
                    conta.conta_contabil !== ''
                      ? `${conta.conta_contabil} | ${conta.des_conta_contabil}`
                      : mensagemBusca
                  }
                />
              </div>
            )}
          </Field>
        </div>
      </AlertBox>
    </>
  );
};

export default ContaContabil;
